import {MainNavbar} from '../../Navigation/Navbars'
import {Figure, Card} from 'react-bootstrap'


export const PageContent = () => {
    return(
        <div>
            <section>
                <MainNavbar />
                <hr className='nav_hr'/>
            </section>
            <section className='secondary_wrapper'>
                <div className='left'>
                    <Figure>
                        <Figure.Image width='400' height='400' alt='400x400' src='./mainlogo2.png' />
                    </Figure>
                </div>
                <div className='right'>
                    <h3>Introducing Kindred Innovation Inc</h3>
                    <div className='inner_right'>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et malesuada fames ac turpis egestas sed tempus urna. Pretium nibh ipsum consequat nisl vel pretium. Aliquam malesuada bibendum arcu vitae.</p>
                    </div>
                </div>
            </section>
            <section className='thirdary_wrapper'>
                <div className='title'>
                    <p>All data was taken from the FMCSA *Federal Motor Carrier Safety Administration* during the years of 2017, 2018 and 2019</p>
                </div>
                <div className='inner_wrapper'>
                    <div className='inner_1'>
                        <Card className='card red'>
                            <Card.Body>
                                <Card.Title></Card.Title>
                                <Card.Text>
                                    There are over 3 billion dollars worth of damages a year lose due to roll overs spread out between,
                                    truck costs, insurance and hospital costs, property and road repairs. Not including the value
                                    of a life of which is priceless.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className='card red'>
                            <Card.Body>
                                <Card.Title></Card.Title>
                                <Card.Text>
                                    There were over 12,000 18 wheeler roll overs, with the average cost of a truck $120,000, there was
                                    over 1.4 billion dollars lost in truck damages alone.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className='card red'>
                            <Card.Body>
                                <Card.Title></Card.Title>
                                <Card.Text>
                                    In those 12,000 roll overs, there were over 330 fatalities involving the truck driver.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='title2'>
                        <p>Integration of our design</p>
                    </div>
                    <div className='inner_2'>
                        <Card className='card blue'>
                            <Card.Body>
                                <Card.Title></Card.Title>
                                <Card.Text>
                                    To integrate our design, it would cost between $4,000 and $8,000 depending on the design
                                    that you chose.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className='card blue'>
                            <Card.Body>
                                <Card.Title></Card.Title>
                                <Card.Text>
                                    Because we will be using the trucks pneumatic system, and added on components, the trailer will be released
                                    within 110th of a second.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className='card blue'>
                            <Card.Body>
                                <Card.Title></Card.Title>
                                <Card.Text>
                                    The time to install and properly configure is between 2 to 4 hours. If it is necessary, we will pay
                                    for a hotel room for the driver.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </section>
        </div>
    )
}