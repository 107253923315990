import { PageContent } from "./Content";
import { MainNavbar } from "../Navigation/Navbars";

export const ContactUs = () => {
    return(
        <div>
            <section>
                <MainNavbar />
                <hr className="nav_hr" />
            </section>
            <section className="secondary_wrapper">
                <PageContent />
            </section>
        </div>
    )
}