import {MainNavigation} from './Components/Navigation/MainNavigation'


import 'bootstrap/dist/css/bootstrap.min.css'
import './Style/main.css'
import './Style/font.css'

function App() {
  return (
    <MainNavigation />
  );
}

export default App;
