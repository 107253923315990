import {Navbar, Nav, NavDropdown} from 'react-bootstrap'

export function MainNavbar() {
    return(
        <Navbar collapseOnSelect expand='lg' className='main_navbar'>
            <Navbar.Brand href='/' className='offset-right'><img alt='' src='/favicon-32x32.png' width='32' height='32' className='d-inline-block align-top'/>{''} Kindred Innovation</Navbar.Brand>
            <Navbar.Toggle aria-controls='main_navbar' />
            <Navbar.Collapse id='main_navbar'>
                <Nav className='me-auto'>
                    <Nav.Link href='/sfwmd'>SFWMD</Nav.Link>
                    <Nav.Link href='/contact'>Contact Us</Nav.Link>
                </Nav>
                <Nav className='offset-left'>
                    <NavDropdown title='Account'>
                        <NavDropdown.Item href='/signup'>Sign Up</NavDropdown.Item>
                        <NavDropdown.Item href='/signin'>Sign In</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href='/'>Sign Out</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>

    )
}

export function Footer() {
    return(
        <div className='footer'>
            <p>Copyright &copy; Kindred Innovation Inc 2022</p>
        </div>
    )
}